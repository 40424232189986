export const translations = {
  no_results: "No results",
  error_warning: "Something went wrong",
  no_items: "No items",
  download_csv: "Download CSV",
  send_via_email: "Send via Email",
  temporary_unavailable: "Temporary unavailable",
  support_email: "bilar@handlarfinans.se",
  sweden: "Sweden",
  finland: "Finland",
  dashboard: {
    dashboard: "Dashboard",
    summary: "Summary",
    stats: "Stats",
    pending_deals: "Pending deals",
    in_inventory: "In inventory",
    avg_inventory_days: "Avg. inventory days",
    total_deals_made: "Total deals made",
    financing: "Financing",
    overdue: "Overdue",
    performing: "Performing",
    used: "used",
    from: "from",
    reserved: "reserved",
  },
  sidebar: {
    dashboard: "Dashboard",
    deals: "Deals",
    support: "Support",
    kyc: "KYC",
    deal_status: {
      pending: "Pending",
      active: "Active",
      settled: "Settled",
    },
    transactions: "Transactions",
    inventory: "Inventory",
    consumer_loans: "Consumer Loans",
    users_management: "Users Management",
    users: "Users",
    invites: "Invites",
    settings: "Settings",
    company_details: "Company details",
  },
  placeholders: {
    deposit_purpose: "Deposit purpose",
    vehicle_not_found: "Vehicle not found",
    save: "Save",
    all_content_types: "All content types",
    downpayment_label: "Down payment (optional)",
    downpayment: "Down payment",
    first_name: "First name",
    last_name: "Last name",
    phone: "Phone",
    email: "Email",
    phone_no: "Phone number",
    filter: "Filter",
    user: "User",
    vin_reg_no: "VIN / Reg number",
    reg_vin: "Reg / VIN code",
    reg_no: "Reg number",
    vin: "VIN",
    make: "Make",
    model: "Model",
    make_model: "Make & Model",
    model_year: "Model year",
    cancel: "Cancel",
    upload: "Upload",
    price: "Price",
    purchase_price_net: "Purchase price net",
    purchase_price_vat: "Purchase price VAT",
    dummy_no: "XX,XX",
    mileage: "Mileage",
    additional_info: "Additional info",
    your_comment: "Your comment",
    not_done: "Not done",
    generate: "Generate",
    files: "Files",
    fetch: "Fetch",
    fetch_info: "Fetch info",
    confirm: "Confirm",
    add_comments: "Add your comments here...",
    submit: "Submit",
    exit: "Exit",
    created_at: "Created at",
    created_before: "Created before",
    created_after: "Created after",
    updated_at: "Updated at",
    status: "Status",
    seller: "Seller",
    save_draft: "Save draft",
    person: "Person",
    organisation: "Organisation",
    entity_type: "Entity type",
    org_no: "Org. no",
    ssn: "SSN",
    ssn_long: "Social security number",
    contact_name: "Contact name",
    address: "Address",
    contact: "Contact",
    additional_contact: "Additional contact",
    name: "Name",
    country_id: "Country ID",
    select_country: "Select country",
    individual: "Individual",
    recipient: "Recipient",
    details: "Details",
    export: "Export",
    date: "Date",
    company_name: "Company name",
    post_address: "Post address",
    postal_code: "Postal code",
    city: "City",
    street: "Street",
    close: "Close",
    vehicle: "Vehicle",
    iban: "IBAN",
    swift: "SWIFT",
    bic: "BIC",
    account_number: "Account number",
    clearing_number: "Clearing number",
    payment_ref: "Payment ref",
    remove: "Remove",
    select: "Select",
    location: "Location",
    add_new: "Add new",
    edit: "Edit",
    yes: "Yes",
    no: "No",
    or: "or",
    preview_file: "Preview file",
    costs: "Costs",
    none: "-",
    invoice: "Invoice",
    state: "State",
    any: "Any",
    countries: "Countries",
    search_country: "Search country",
    country_or_residence: "Country of residence",
    continue: "Continue",
    back: "Back",
    all: "All",
  },
  deals: {
    overdue: "Overdue",
    due_days: "{{days}} days",
    one_due_day: "1 day",
    payment_overdue: "Payment overdue",
    successfully_created: "Deal was successfully created",
    create_error_warning: "Something went wrong while creating the deal",
    change_deal_success: "Deal was successfully changed",
    change_deal_error: "Something went wrong while changing the deal",
    add_new_deal: "New deal",
    add_new_deal_success: "Deal was successfully created",
    add_new_deal_error: "Something went wrong while creating the deal",
    action_required_message: "You have a pending action required",
    continue: "Continue",
    go_to_deal: "Go to deal",
    active_deal: "Active deal",
    vat_deductible: "VAT Deductible",
    vat_nondeductible: "VAT not deductible",
    margin_scheme: "Margin scheme",
    registered_owner: "Registered owner",
    marketplace: "Marketplace",
    own_stock: "Own stock",
    proceed: "Proceed",
    request_financing: "Request financing",
    fetch_error: "Something went wrong while fetching the deal",
    deals: "Deals",
    no_deal: "No Deal",
    ownership: "Ownership",
    payment_required: "Payment required",
    missing_data: "Something is missing",
    exit_deal: "Exit deal",
    exit_deal_unsaved_changes:
      "You have unsaved changes. Are you sure you want to exit the current draft deal? The unsaved data will be lost.",
    data_room: "Data room",
    advance: "Advance",
    request_offer_success: "Financing offer was successfully requested",
    request_offer_error: "Something went wrong while requesting the offer",
    financed_amount: "Financed amount",
    inventory_days: "Inventory days",
    payment_due_in: "Payment due in",
    export_transaction_report: "Export transaction report",
    export_transaction_report_success: "Transaction report was successfully generated.",
    export_transaction_report_error: "Something went wrong while generating the transaction report.",
    export_transaction_report_warning: "Couldn't generate the transaction report.",
    deal_status: {
      initiated: "Initiated",
      offer_requested: "Offer requested",
      pending: "Pending",
      offer_accepted: "Offer accepted",
      prepared: "Prepared",
      servicing: "Servicing",
      cancelled: "Cancelled",
      settled: "Settled",
    },
    deal_sections: {
      vehicle: "Vehicle",
      dealer: "Dealer",
      purchase: "Purchase",
      offering: "Offering",
      payout: "Payout",
      ownership: "Ownership",
      servicing: "Servicing",
      contracts: "Contracts",
      payments: "Payments",
    },
  },
  files: {
    preview: "Preview",
    files: "Files",
    create_update_at: "Created/updated at",
    owner: "Owner",
    file_name: "File name",
    size: "Size",
    no_file: "No files",
    success_message: "File was successfully deleted",
    error_message: "Unable to delete the file",
    download: "Download",
    delete: "Delete",
    upload_failed: "- Upload failed, please try again.",
    replace_file: "Replace file",
    delete_file: "Delete file",
    delete_warning: "You are about to delete {{fileName}} file. Are you sure?",
    files_page: "files",
    upload_purchase_invoice: "Upload purchase invoice",
    drag_drop: "Drag & drop your files here, or click to select",
    upload_receipt: "Please upload your payment receipt using the payment details above",
    upload_file: "Upload file",
    tag: "Labels",
    successfully_uploaded: "File(s) was successfully uploaded",
    error_on_uploaded: "Something went wrong while uploading {{fileName}} file",
    error_display_file_type: "Unable to display this type of file",
    labels: {
      contract: "Contract",
      receipt: "Receipt",
      payout_receipt: "Payout receipt",
      advance_receipt: "Advance receipt",
      servicing: "Servicing",
      ownership: "Ownership",
      image: "Image",
      pdf: "PDF",
      purchase_contract: "Purchase contract",
      ownership_paper: "Ownership paper",
      proof_of_vehicle: "Proof of vehicle",
      contract_base: "Contract base",
      all: "All",
      report: "Report",
      finance: "Finance",
      advance_invoice: "Advance invoice",
      purchase_document: "Purchase document",
    },
  },
  purchase: {
    indicative_signatory: "Seller signatory",
    financier: "Financier",
    seller: "Seller",
    contract_parties: "Contract parties",
    labels: {
      debt_amount: "Vehicle debt Amount",
      debt_vat: "Vehicle debt VAT",
      debt_note: "Upload debt note",
      payment_method: "Payment method",
      amount: "Amount",
    },
    trade_in: "Trade in",
    trade_in_check: "This vehicle is part of a trade in deal",
    sales_price_net: "Sales price net",
    sales_price_vat: "Sales price VAT",
    invoice_prompt: "Is there an invoice for the purchase?",
    yes: "Yes",
    no_invoice: "No, I want to generate a contract",
    invoice_info: "You can always email us the invoice on ",
    payment_information: "Payment information",
    invoice: {
      invoice_modal_title: "Invoice details: {{total}}",
      view_invoice: "View",
      back_to_invoice: "Back to invoice details",
    },
  },
  offering: {
    estimated_price: "Estimated sales price",
    requested_financing: "Requested financing",
    financing_offer: "Financing offer",
    total_purchase_price: "Total purchase price",
    financing_rate: "Financing rate",
    financing_amount: "Financing amount",
    advance_amount: "Advance amount",
    monthly_interest: "Monthly interest",
    standard_inv_financing: "Standard inventory financing",
    choose_financing: "Choose financing",
    deal_received: "We received your deal",
    checking_deal: "Please wait while we are checking the deal. We'll get back shortly...",
    contact_us: "Contact us",
    accept_offer: "Accept offer",
    accept_offer_success: "Offer was successfully accepted",
    accept_offer_error: "Something went wrong while accepting the offer",
    update_deal: "Update deal",
    abort: "Abort",
    abort_success: "Request was successfully aborted",
    abort_error: "Something went wrong while aborting the request",
    check_deal_status: "Check deal status",
  },
  vehicle: {
    successfully_created: "The vehicle was successfully created",
    create_error_warning: "Something went wrong while saving vehicle data",
    no_vehicle_added: "No vehicle added",
    successfully_edited: "Vehicle was successfully updated",
    edit_error_warning: "Something went wrong while updating vehicle data",
    first_reg_date: "First registration date",
    current_registration: "Current registration",
    fuel_type: "Fuel type",
    petrol: "Petrol",
    diesel: "Diesel",
    hybrid: "Hybrid",
    electric: "Electric",
    drive_type: "Drive type",
    FWD: "FWD",
    RWD: "RWD",
    AWD: "AWD",
    transmission: "Transmission",
    manual: "Manual",
    automatic: "Automatic",
    power: "Power",
    VAT_status: "VAT Status",
    VAT_status_undefined: "VAT status undefined",
    deductible: "VAT Deductible",
    margin_scheme: "Margin scheme",
    current_owner: "Current owner",
    estimated_sales_price: "Estimated sales price",
    information: "Information",
    fetch_vehicle_info: "Fetch vehicle info",
    import_error: "Could not import vehicle info",
    additional_info: "Additional vehicle info",
    additional_info_description:
      "Tell Handlarfinans more about the vehicle, e.g. condition, equipment or other special characteristics.",
    upload_img_doc: "Upload images/documentation",
    search_placeholder: "Search vehicle",
    additional_comment: "Additional comment",
    vehicle_info: "Vehicle info",
    no_of_keys_received: "No. of keys received",
    no_of_keys_received_required: "No. of keys received is required",
    additional_tyres_included: "Additional tyres included",
    add_vehicle: {
      search_add_label: "Search or add new vehicle",
      add_new: "Add new vehicle",
    },
  },
  contracts: {
    add_contract_error: "Add contract error",
    contract_signing_initiated_success: "Contract was successfully created and signing process initiated",
    signatories: "Signatories",
    contract_preview: "Contract preview",
    purchase_contract: "Purchase documents",
    generate_contract: "Generate contract",
    financing_contract: "Financing contract",
    other_documents: "Other documents",
    sellers: "Sellers",
    buyers: "Buyers",
    status: {
      init: "Initiated",
      pending: "Pending",
      closed: "Closed",
      cancelled: "Cancelled",
      expired: "Expired",
      rejected: "Rejected",
      error: "Error",
      delivered: "Delivered",
      viewed: "Viewed",
      signed: "Signed",
    },
    no_signee: "No designated signer",
  },
  payments: {
    pay_advance: "Pay advance",
    advance_to_info: "Make your advance payment to Handlarfinans",
    pay_now: "Pay now",
    advance: "Advance",
    leasing: "Leasing",
    advance_status: {
      paid: "Paid",
    },
    payouts_from_handlarfinans: "Payouts from Handlarfinans",
    dealer_deposit_state: {
      new: "New",
      pending: "Pending",
      approved: "Approved",
      processing: "Processing",
      processed: "Processed",
      rejected: "Rejected",
      voided: "Voided",
    },
    payout_task_state: {
      new: "New",
      in_process: "In process",
      processed: "Processed",
      cancelled: "Cancelled",
      pending: "Pending",
      authorized: "Authorized",
      rejected: "Rejected",
      approved: "Approved",
      expired: "Expired",
    },
    advance_payment_success: "Advance payment was successfully made",
    advance_payment_error: "Advance payment has failed",
    settlement: {
      success: "Settlement payment was successfully made",
      error: "Settlement payment has failed",
    },
    extend: {
      success: "Extend payment was successfully made",
      error: "Extend payment has failed",
    },
    payment_link_error: "Something went wrong while generating payment link",
  },
  servicing: {
    manual_deposit_success: "Payment receipt registered successfully",
    make_manual_deposit: "Register payment receipt",
    instant_payment: "Instant payment",
    financials: "Financials",
    service_deal: "Service deal",
    total_purchase_price: "Total purchase price",
    monthly_interest: "Monthly interest",
    financing_amount: "Financing amount",
    amount_to_extend: "Amount to extend",
    amount_to_settle: "Amount to settle",
    standard_inv_financing: "Standard inventory financing",
    make_payment: "Make payment",
    extend: "Extend",
    settle: "Settle",
    pay_instalment: "Pay instalment",
    amount_due_in_no_data: "Amount due in",
    amount_due_in: "Amount due in {{days}}",
    amount: "Amount",
    purpose: "Purpose",
    upload_payment_receipt: "Upload payment receipt",
    payment_receipt_info:
      "Payment receipt uploaded successfully. One of our agents will verify the file and record the payment",
    payment_details: "Payment details",
    payment_ref: "Payment ref",
    iban: "IBAN",
    swift: "SWIFT",
    bankgiro_id: "Bankgiro ID",
    deposits: "Deposits",
    no_deposits: "No deposits",
    deposit_purpose_options: {
      settlement: "Settlement",
      extend: "Extend",
      advance: "Advance",
      financing: "Financing",
      dividend: "Dividend",
    },
    payment_unavailable: "Please contact support for instructions on how to settle this invoice.",
  },
  section_roles: {
    dealer: "Dealer",
    seller: "Seller",
    seller_agent: "Seller Agent",
    seller_financier: "Seller Financier",
    vat_deposit_escrow: "VAT Deposit",
    transferor: "Ownership Transferor",
  },
  account: {
    my_account: "My account",
    logout: "Logout",
    email: "Email",
    name: "Name",
    save: "Save",
    update_account_success_message: "Account was successfully updated",
    update_account_error_message: "Something went wrong while updating account data",
  },
  ownership: {
    auth_code: "Authorisation code",
    seller_transfers_ownership: "Seller transfers ownership",
    ownership: "Ownership",
    ownership_transfer: "Ownership transfer",
    ownership_papers: "Ownership papers",
    ownership_manual_transfer: "The ownership will be manually transferred",
    ownership_done: "Done",
    ownership_pending: "Pending",
    ownership_transfer_success_message: "Transfer ownership successfully initiated",
    ownership_transfer_error_message: "Transfer ownership error",
    ownership_control_number_already_exist: "Trade-in transaction already exist",
    ownership_control_number_error: "Invalid control number",
  },
  settled: {
    settled_deal: "Settled deal",
    transaction_history: "Transaction history",
    purpose: "Purpose",
    amount: "Amount",
  },
  date_time: {
    year: "Year",
    years: "Years",
    month: "Month",
    months: "Months",
    weeks: "Weeks",
    week: "Week",
    day: "Day",
    days: "Days",
    and: "and",
  },
  support: {
    support: "Support",
    unavailable_chat: "Chat is currently unavailable",
    load_more: "Load more",
  },
  kyc: {
    draft_save_error: "Something went wrong while saving the draft",
    draft_successfully_saved: "Draft was successfully saved",
    form_validation_error: "Please fill in all required fields",
    input_error: "This field is required",
    register_pending_message1: "We are unable to process your request at this moment. Please contact us at ",
    register_pending_message2: " for support",
    application_created_success: "Application was successfully created",
    application_created_error: "Something went wrong while creating application",
    update_application_success: "Application was successfully updated",
    update_application_error: "Something went wrong while updating application data",
    updated_at: "Updated at",
    terms: {
      title: "Welcome to Handlarfinans!",
      terms_copy:
        "Handlarfinans is under supervision from the Financial Supervisory Authority and needs to continuously update the KYC (Know Your Customer) of its existing partners. This is an important part of the work to combat money laundering and terrorist financing. We therefore ask you to fill in answers to the questions and send them to us by April 21 at the latest. In connection with this, it may be required that we need to take a new UC for the company if the one we took last is over 6 months old, and that we will need to take a new Credit Safe for the entire board and for all people who have signed a cooperation agreement with Handlarfinans about it we have today is older than 12 months old.",
      accept_terms: "I accept the terms above",
    },
    identity: {
      title: "Verify your identity",
      upload_id: "Upload an image of your ID card",
      login_with_bankID: "Log in with BankID",
      login_without_bankID: "Continue without BankID ",
      id_uploaded_success: "ID image was successfully uploaded",
      id_uploaded_error: "There was an error uploading your ID",
      replace_id: "Replace ID photo",
      click_to_replace: "Click on image to replace it",
    },
    application: {
      title: "Finish your setup",
      personal_info: "Personal information",
      finish: "Finish",
      company: "Company",
      company_question: "What company should be connected to this account?",
      other: "Other",
      confirm_company_info: "Confirm company information",
      confirm_owner: "Confirm beneficial owner",
      beneficial_owner_pep: "The beneficial owner is PEP?",
      yes: "Yes",
      no: "No",
      part_of_group: "Is the company part of a group",
      org_structure: "Describe the org structure",
      group_active_abroad: "Are any of the group entities active abroad",
      org_countries_abroad: "Which countries?",
      owners_years_experience_in_car_sale: "How many years of experience in the car sales industry?",
      purpose: "Purpose of using Handlarfinans",
      inventory_financing: "Inventory financing",
      expansion: "Expansion",
      debt_financing: "Debt-financing",
      marketing_activities: "Marketing activities",
      purpose_other: "Type your reason",
      vehicle_types_question: "What type of vehicles do you sell?",
      passenger_cars: "Passenger cars",
      sport_cars: "Sport cars",
      transport_vehicles: "Transport vehicles",
      campers: "Campers & Caravans",
      snowmobiles_atv: "Snowmobiles & ATV",
      trailers: "Trailers",
      purchase_price_range: "Which pricing interval does mostly match the prices your company buys vehicles for?",
      up_to_price: "Up to 300 000 SEK",
      between_price: "300 000 - 500 000 SEK",
      price_and_above: "500 000 SEK and above",
      garage_info: "Garage information",
      enter_webpage: "Please enter the webpage where you list your cars for sale",
      online_shop_url: "Online shop url",
      enter_store_address: "Please enter the address where you store and show your vehicles for sale",
      same_as_company_address: "Same as company address",
      enter_garage_size: "Please enter the size of your garage and yard lot",
      garage_size: "Garage size",
      cars_in_garage: "Number of cars in garage",
      yard_size: "Yard size",
      cars_in_yard: "Number of cars in yard",
      about_your_business: "About your business",
      owner_industry_experience: "Owners years of experience, within the vehicle industry",
      employees_no: "Number of employees (Full and part time)",
      other_income_sources: "Other sources of income",
      cash_manage: "Do you manage cash within the business?",
      cash_manage_options: {
        frequently: "Yes, with relative frequency",
        as_an_exception: "Yes, as an exception and only when the customer can prove the origin",
        rarely: "Yes, not frequently and only for deposit",
        no: "No",
      },
      vehicle_sourcing_channels: "Where do you buy the majority of vehicles from?",
      vehicle_sourcing_channel: {
        auctions: "Auctions",
        car_dealers: "Car dealers",
        other_companies: "Other companies",
        private_individuals: "Private individuals",
        import: "Import",
      },
      dealership_photos: "Dealership photos",
      dealership_photos_info: "Add at least one picture inside and one of the lot.",
      upload_images: "Upload images",
      budget: "Budget",
      estimated_purchase_costs: "Estimated vehicle purchase costs",
      gross_profit: "Gross profit",
      expected_turnover: "Expected turnover",
      facility_expenses: "Facility expenses",
      salaries_and_employee_costs: "Salaries and employee costs",
      other_expenses: "Other expenses",
      expected_result: "Expected result",
      board_members: "Board members",
      add_another: "Add another",
      role: "Role",
      holder: "Holder",
      commissioner: "Commissioner",
      substitute: "Substitute",
      ceo: "CEO",
      deputy_ceo: "Deputy CEO",
      member: "Member",
      chairman: "Chairman",
      alternate_deputy_substitute_ceo: "Alternate deputy substitute CEO",
      executive_member: "Executive member",
      vice_chairman: "Vice chairman",
      percentage_of_ownership: "Percentage of ownership",
      ownership_range: {
        0: "0%",
        "0_25": "0-25%",
        "25_50": "More than 25% but not more than 50%",
        "50_75": "More than 50% but not more than 75%",
        "75_100": "More than 75% but not more than 100%",
        full: "100%",
      },
      is_beneficial_owner: "Is beneficial owner",
      is_pep: "Is PEP",
      has_additional_citizenship: "Has other citizenship",
      additional_citizenship: "Additional citizenship",
      additional_citizenship_placeholder: "Finland, Norway, ...",
      control_type: "Control type",
      control_type_options: {
        art10:
          "The person has control through shares, participation, memberships, agreements or provisions in for example the articles of association.",
        art14: "The person is a board member or equivalent executive.",
        art20:
          "The person has the right to appoint or remove more than half of the board members or corresponding executives.",
        art30: "The person has control together with close relatives.",
        art40: "The person has control through other companies or associations.",
        art74: "The person exercises control in another way.",
        no_control: "The person has no control.",
      },
    },
    application_submitted: {
      title: "All done!",
      submission_info:
        "We received your application. Please allow one of our employees a moment to check all the information you submitted. Once approved, we will notify you via email.",
    },
    application_rejected: {
      title: "Application rejected",
      rejection_info:
        "We are sorry to inform you that your application has been rejected. Please contact us at support@handlarfinans.se",
    },
  },
  required_actions: {
    title: "You have one required action",
    title_plural: "You have {{count}} required actions",
    description: "Before requesting a new deal, please make sure you fix all the following required actions.",
    card_title: "Required actions",
    no_actions: "You have no required actions",
    action_types: {
      ownership_transfer_outstanding: "Ownership transfer not completed for ",
      payment_outstanding: "You have payments outstanding",
      credit_limit_exceeded: "Credit limit exceeded",
      contact_support: "Contact support",
      kyc_required: "You have a pending KYC application",
    },
  },
  bank_id_auth: {
    try_again: "Try again",
    authentication_failed: "BankID authentication failed.",
    title: "BankID Authentication",
    error: "Something went wrong while authenticating with BankID",
    success: "Successful BankID authentication",
    open_app: "Open BankID app",
    hint_messages: {
      RFA1: "Start your BankID app",
      RFA9: "Enter your security code in the BankID app and select Identify or Sign.",
      RFA13: "Trying to start your BankID app.",
      RFA15:
        "Searching for BankID:s, it may take a little while… If a few seconds have passed and still no BankID has been found, you probably don’t have a BankID which can be used for this identification/signing on this computer. If you have a BankID card, please insert it into your card reader. If you don’t have a BankID you can get one from your bank.",
      RFA21: "Identification or signing in progress.",
      RFA23: "Process your machine-readable travel document using the BankID app.",
    },
  },
  transactions: {
    transactions_title: "Transactions",
    select_period: "Select a time period",
    custom: "Custom",
    last_30_days: "Last 30 days",
    current_quarter: "Current quarter",
    from_date: "From date",
    to_date: "To date",
    generate_transaction_pdf: "Generate transaction PDF",
    generated_files: "Generated files",
    action: "Action",
    file_generated_successfully: "File was successfully generated",
    file_generation_error: "Something went wrong while generating the file",
  },
  inventory: {
    inventory: "Inventory",
    any_location_status: "Any location status",
    new_vehicle_purchase: "New vehicle purchase",
    edit_vehicle_purchase: "Edit vehicle purchase",
    purchase: "Purchase",
    purchase_price: "Purchase price",
    purchase_price_inc_vat: "Purchase price inc. VAT",
    purchase_date: "Purchase date",
    purchase_date_from_doc: "Purchase date (from receipt/invoice)",
    purchased_by: "Purchased by",
    purchase_note: "Purchase note",
    purchase_doc_label: "Purchase doc",
    purchase_channel: "Purchase channel",
    purchase_information: "Purchase information",
    upload_purchase_doc: "Upload purchase document",
    purchase_doc_sent: "Purchase document sent",
    register_purchase: "Register purchase",
    expected_sale_price: "Expected sale price",
    sale_doc: "Sale doc",
    sale_channel: "Sale channel",
    sold_vehicle_details: "Sold vehicle details",
    sale_information: "Sale information",
    sale_date: "Sale date",
    total_selling_price: "Total selling price",
    sold_with_warranty: "Sold with warranty",
    sold_with_financing: "Sold with financing",
    upload_sale_doc: "Upload sale document",
    marketplace: "Marketplace",
    inventory_vehicle_details: "Inventory vehicle details",
    interest_rate: "Interest rate",
    warranty: "Warranty",
    warranty_provider: "Warranty provider",
    financed: "Financed",
    financier: "Financier",
    customer_type: "Customer type",
    customer_country: "Customer country",
    first_reg_date: "First reg. date",
    vat_status: "VAT status",
    advance_payment: "Advance payment",
    advance_amount: "Advance amount",
    upload_advance_invoice: "Upload advance invoice",
    advance_payment_spec_sent: "Advance payment specification sent",
    total_logistics: "Total logistics",
    total_logistics_net: "Total logistics net",
    total_logistics_vat: "Total logistics vat",
    last_logistic_date: "Last logistic date",
    current_location: "Current location",
    location_status_label: "Location status",
    location_label: {
      dealership: "Dealership",
      in_transit: "In transit",
      repair_shop: "Repair shop",
      other: "Other",
    },
    ownership_status: {
      in_stock: "In stock",
      sold: "Sold",
      stolen: "Stolen",
      scrapped: "Scrapped",
      unknown: "Unknown",
    },
    purchase_doc: {
      invoice: "Invoice",
      purchase_agreement: "Purchase agreement",
    },
    purchase_channel_options: {
      lead_from_private_person: "Lead from private person",
      lead_from_company: "Lead from company",
      blocket_ad_from_car_dealer: "Blocket ad from car dealer",
      blocket_ad_from_other: "Blocket ad from other",
      marketplace: "Marketplace",
      reoccurring_customer: "Reoccurring customer",
    },
    marketplace_options: {
      autorola: "Autorola",
      bca: "BCA",
      kvd: "KVD",
      klaravik: "Klaravik",
    },
    sale_doc_options: {
      car_dealer: "Car dealer",
      private_person: "Private person",
      blocket: "Blocket",
      kvd: "KVD",
      bca: "BCA",
      auto1: "Auto1",
      other: "Other",
      purchase_agreement: "Purchase agreement",
    },
    sale_channel_options: {
      showroom: "Showroom",
      blocket: "Blocket",
      other: "Other",
    },
    ownership_status_filters: {
      all: "All vehicles",
      in_stock: "In inventory",
      sold: "Sold vehicles",
    },
    customer_type_options: {
      private: "Private",
      company: "Company",
    },
    customer_country_options: {
      SE: "Sweden",
      FI: "Finland",
    },
    messages: {
      status_updated: "Vehicle status was successfully updated",
      status_update_error: "Something went wrong while updating vehicle status",
      new_transaction_added: "New transaction was successfully added",
      new_transaction_error: "Something went wrong while adding new transaction",
      vehicle_updated: "Vehicle inventory was successfully updated",
      vehicle_update_failed: "Something went wrong while updating vehicle inventory data",
      location_updated: "Vehicle location was successfully updated",
      location_update_failed: "Something went wrong while updating vehicle location data",
      inventory_vehicle_added: "Vehicle was successfully added to inventory",
    },
    warranty_providers: {
      svensk_bilhandelsförsäkring: "Svensk Bilhandelsförsäkring",
      solid: "Solid",
      fragus: "Fragus",
    },
  },
  register_action: {
    mark_as_sold: "Mark as sold",
    reg_new_action: "Register new action",
    reg_costs: "Register costs",
    update_location: "Update location",
    sell_vehicle: "Sell vehicle",
    cost_type: "Cost type",
    cost_amount: "Cost amount",
    cost_amount_net: "Cost amount net",
    vat_amount: "VAT amount",
    comment: "Comment",
    upload_cost_invoice: "Upload cost invoice",
    vehicle_current_location: "Vehicle current location",
    set_status: "Set status",
    reg_vehicle_sell: "Register vehicle sell",
    cost_types: {
      expenses: "Expenses",
      expense: "Expense",
      reimbursements: "Reimbursements",
      reimbursement: "Reimbursement",
    },
  },
  events: {
    event: "Event",
    events: "Events",
    vehicle: "vehicle",
    vehicle_camel_case: "Vehicle",
    new: "A new",
    a: "A",
    by: "by",
    was_created: "was created",
    was_created_by: "was created by",
    was_deleted: "was deleted by",
    was_updated: "was updated by",
    created_by: "created by",
    deleted_by: "deleted by",
    updated_by: "updated by",
    total_count: "Event log ({{total_count}})",
    load_more: "Load more",
  },
  event_logs: {
    no_logs: "No event logs available",
    no_tags: "No tags available",
  },
  upcoming_events: {
    upcoming_events: "Upcoming events",
    upcoming_payment: {
      message: "Payment of {{amount}} due on the {{date}} for {{vehicle_title}}",
    },
  },
  bank_account_config: {
    bank_representation_type: "Bank representation type",
  },
  payment_plan: {
    show_payment_plan: "Show payment plan",
    payment_plan_title: "Payment plan",
    no_payment_plan: "No payment plan",
    payment_date: "Payment date",
    amount: "Amount",
    interest_amount: "Interest amount",
    principal_amount: "Principal amount",
    remaining_balance: "Remaining balance",
  },
  invoice_details: {
    invoice_title: "Invoice #{{invoiceNo}}",
    invoice_no: "Invoice no",
    purpose: "Purpose",
    total: "Total",
    status: "Status",
    net: "Net",
    vat: "VAT",
    due: "Due",
    balance: "Balance",
    description: "Description",
    invoice_types: {
      advance: "Advance",
      financing: "Financing",
      credit_financing: "Credit financing",
      credit_balancing: "Credit balancing",
      interest: "Interest",
    },
    invoice_status: {
      paid: "Paid",
      paid_invoice: "Paid invoice",
      unpaid: "Unpaid",
      overdue: "Overdue",
      unbooked: "Unbooked",
      booked: "Booked",
    },
  },
  tags: {
    internal_user: "Internal user",
    external_user: "External user",
    vehicle: "Vehicle",
    file: "File",
    legal_entity: "Contact",
    dealer_credit: "Dealer credit",
    dealer_account: "Dealer account",
    deal: "Deal",
    vehicle_site_audit: "Vehicle site audit",
    kyc_application: "KYC application",
    outbound_email: "Outbound email",
    dealer_location: "Dealer location",
    monitoring_schedule: "Monitoring schedule",
    vehicle_valuation: "Vehicle valuation",
    signing_process: "Signing process",
    payout_task: "Payout task",
    invoice: "Invoice",
    state_assertion: "State assertion",
    see_credit: "See credit",
  },
  deal_validation: {
    invalid_deal: "Policy limitation",
    back_to_dashboard: "Back to dashboard",
    invalid_deal_details: "This vehicle is limited by Handlarfinans policies and can therefore not be financed.",
  },
  suggested_input_value: {
    popover_trigger: "Suggested value popover trigger",
    vat_rate: "VAT rate: {{rate}}%",
    use: "Use",
  },
  onboarding: {
    account_info: "Account information",
    start_info: "Let's start by adding your personal information",
    company_info: "Company information",
    company_info_description: "What company should be connected to this account?",
    no_bank_id: "I don't have a BankID",
    continue_with_bank_id: "Continue with BankID",
    fi_coming_soon_title: "The sign up process is coming soon for Finland",
    fi_coming_soon_details: "If you want to sign up now, please contact us at laskut@handlarfinans.fi",
    legal_entity_created: "Legal entity was successfully created",
    legal_entity_error: "Something went wrong while creating legal entity",
    se_temp_unavailable_title: "We are unable to process your request at this moment.",
    se_temp_unavailable_details: "Please contact us at bilar@handlarfinans.se for support.",
    no_companies_title: "We failed to find any companies under your control.",
    no_companies_details: "Please contact us at bilar@handlarfinans.se for assistance.",
    continue: "Continue",
    submit_identity_data_error: "Something went wrong while submitting identity data",
    verification_pending_title: "We received your application",
    verification_pending_details:
      "Please allow one of our employees a moment to check all the information you submitted. Once approved, we will notify you via email.",
    verification_rejected_title: "Your identity verification process was rejected",
    verification_rejected_details: "Please contact us at bilar@handlarfinans.se for support.",
    fill_all_required_fields: "Please fill out all required fields",
  },
  loans: {
    cancel_success: "Loan application was successfully cancelled",
    cancel_error_warning: "Something went wrong while cancelling the loan application",
    cancel_application_warning: "Are you sure you want to cancel the loan application?",
    cancel_application: "Cancel application",
    loan_application: "Loan application",
    product: "Product",
    create_success: "New loan application initiated. We will send the applicant an SMS with next steps.",
    create_error_warning: "Something went wrong while creating the loan application",
    fill_all_fields: "Please fill in all required fields",
    email_or_phone_required: "Please provide a phone number.",
    initiate_application: "Apply",
    credit_product: "Credit product",
    loans_title: "Loans",
    applicant_email: "Applicant email",
    applicant_phone_no: "Applicant phone number",
    credit_amount: "Amount",
    new_loan_application: "New loan application",
    new_applicant: "New applicant",
    applicant_info: "Applicant information",
    loan_application_details: "Loan application details",
    loan_details: "Loan details",
    credit_amount_warning: "The amount has to be divisible by 250",
    credit_amount_min_warning: "The amount has to be at least {{min_amount}}",
    credit_amount_max_warning: "The amount has to be at most {{max_amount}}",
    repayment_period_min_warning: "The repayment period has to be at least {{min_period}}",
    repayment_period_max_warning: "The repayment period has to be at most {{max_period}}",
    loan_status: {
      new: "New",
      pending: "Pending",
      approved: "Approved",
      denied: "Denied",
      paid_out: "Paid out",
      error: "Error",
      signed: "Signed",
    },
    filters: {
      all: "All",
      new: "New",
      pending: "Pending",
      manual_check: "Under review",
      approved: "Approved",
      denied: "Denied",
      paid_out: "Paid out",
      error: "Error",
      signed: "Signed",
    },
    product_key: {
      blanco: "Blanco",
    },
    loan_calculator_example: "Loan Calculator",
    years: "yrs",
    repayment_period: "Repayment period",
    monthly_cost: "Monthly cost",
    calculation_example:
      "The calculation serves as an indicative example only. Exact monthly cost including effective interest and fees will be presented to the customer in first step of application.",
    no_applications: "No applications",
    dealer_details: "Dealer details",
    financed_by_hf: "Financed by Handlarfinans",
    other: "Other",
    amount_to_settle: "Amount to settle",
    earnings_using_blanco_loan: "Earnings using Blanco loan",
    get_offer: "Get offer",
    credit_offer_type: {
      credit_discount: "Credit discount",
      fixed_amount: "Fixed amount",
    },
    settle_with_consumer_loan: "Earn up to {{bonus}} by using Consumer loans to settle this deal.",
    apply_now: "Apply now",
    offer_unavailable: "Offer unavailable",
    offer_fetched: "Loan offer was successfully fetched",
    error_fetching_offer: "Something went wrong while fetching the loan offer",
    offer_type: "Offer type",
    discount_percentage: "Discount percentage",
    discount_amount: "Discount amount",
    amount: "Amount",
  },
  system_messages: {
    info: "Info",
    warning: "Warning",
    error: "Error",
  },
  users_management: {
    user_remove_success: "User was successfully removed",
    user_remove_error: "Something went wrong while removing user",
    remove_external_user: "Remove external user",
    remove_user_warning: "Are you sure you want to remove this user?",
    page_title: "Users management",
    users: "Users",
    invites: "Invites",
    invite_new_user: "Invite new user",
    invite_user: "Invite user",
    invite_user_success: "The invitation email was successfully sent",
    invite_user_error: "Something went wrong while inviting user",
    resend_invite: "Resend invite",
    resend_invite_success: "The invitation email was successfully resent",
    resend_invite_error: "Something went wrong while resending the invitation email",
    cancel_invite: "Cancel invite",
    cancel_invite_success: "The invitation was successfully cancelled",
    cancel_invite_error: "Something went wrong while cancelling the invitation",
    save_user: "Save user",
    state: "State",
    external_user_state_options: {
      new: "New",
      active: "Active",
      deleted: "Deleted",
    },
    external_user_state_filters: {
      new: "New",
      active: "Active",
    },
    filter_users: "Filter users",
    user_role: "User role",
    select_role: "Select role",
    external_user_roles_options: {
      owner: "Owner",
      employee: "Employee",
      manager: "Manager",
      accountant: "Accountant",
      prospect: "Prospect",
    },
    fetch_roles_error: "Something went wrong while fetching user roles",
    edit_user: "Edit user",
    set_roles_success: "User roles were successfully updated",
    set_roles_error: "Something went wrong while updating user roles",
    revoke_role_success: "Role was successfully removed from user",
    revoke_role_error: "Something went wrong while removing user role",
    grant_role_success: "Role was successfully added to user",
    grant_role_error: "Something went wrong while adding user role",
  },
  dealer_account: {
    edit_info: "Edit account information",
    account_update_success: "Account information was successfully updated",
    account_update_error: "Something went wrong while updating account information",
  },
};
