import { useNavigate, useLocation, Link as ReactRouterLink } from "react-router-dom";
import {
  Flex,
  Image,
  Box,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { Authorize, Permissions } from "../permissions";
import useTranslate from "../locale/hooks/useTranslate";
import Icon from "../shared/Icon";
import { useGetLastKycApplication } from "../kyc/hooks/useGetKYCPendingApplication";
import FeatureGate from "./FeatureGate";
import { FeatureFlagEnum } from "../account/types";

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const translate = useTranslate();

  const { kycApplicationId, kycApplicationStatus } = useGetLastKycApplication();
  const isActive = (current_page: string | string[]): boolean => {
    return Array.isArray(current_page)
      ? current_page.some((page) => location.pathname.startsWith(page))
      : location.pathname.startsWith(current_page);
  };

  const getVariant = (current_page: string | string[], isSubMenu?: boolean): string => {
    if (isSubMenu) {
      return isActive(current_page) ? "sidebarSectionLinkSelected" : "sidebarSectionLink";
    }

    return isActive(current_page) ? "sidebarLinkSelected" : "sidebarLink";
  };

  return (
    <Flex as="nav" aria-label="Sidebar Navigation" layerStyle="sidebarNav">
      <Box layerStyle="sidebarLogo">
        <Image
          cursor="pointer"
          src="/images/logo-white.svg"
          alt="Handlarfinans logo"
          onClick={(_) => navigate("/deals-pending")}
        />
      </Box>

      <Box layerStyle="sidebarMenuWrapper">
        <ChakraLink as={ReactRouterLink} to="/dashboard" variant={getVariant("/dashboard")}>
          <Flex alignItems="center" w="full">
            <Icon name="home" />
            <Text ml="2">{translate("sidebar.dashboard")}</Text>
          </Flex>
        </ChakraLink>

        <FeatureGate features={[FeatureFlagEnum.VEHICLE_INVENTORY]}>
          <Authorize require={[Permissions.READ_INVENTORY_VEHICLE]}>
            <ChakraLink as={ReactRouterLink} to="/inventory" variant={getVariant("/inventory")}>
              <Flex alignItems="center" w="full">
                <Icon name="directions_car" />
                <Text ml="2">{translate("sidebar.inventory")}</Text>
              </Flex>
            </ChakraLink>
          </Authorize>
        </FeatureGate>
        <Authorize require={[Permissions.READ_DEAL]}>
          <Accordion
            reduceMotion
            variant="sidebarMenuAccordion"
            allowToggle
            defaultIndex={isActive("/deal") || isActive("/servicing") || isActive("/settled") ? [0] : undefined}
          >
            <AccordionItem>
              <AccordionButton>
                <Flex alignItems="center" w="full">
                  <Icon name="emoji_transportation" mr="2" />
                  <Text>{translate("sidebar.deals")}</Text>
                </Flex>
              </AccordionButton>
              <AccordionPanel>
                <ChakraLink
                  as={ReactRouterLink}
                  to="/deals-pending"
                  variant={getVariant(["/deals-pending", "/deal/"], true)}
                >
                  <Text>{translate("sidebar.deal_status.pending")}</Text>
                </ChakraLink>

                <ChakraLink
                  as={ReactRouterLink}
                  to="/deals-active"
                  variant={getVariant(["/deals-active", "/servicing"], true)}
                >
                  <Text>{translate("sidebar.deal_status.active")}</Text>
                </ChakraLink>

                <ChakraLink
                  as={ReactRouterLink}
                  to="/deals-settled"
                  variant={getVariant(["/deals-settled", "/settled"], true)}
                >
                  <Text>{translate("sidebar.deal_status.settled")}</Text>
                </ChakraLink>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Authorize>

        <Authorize require={[Permissions.READ_EXTERNAL_USER, Permissions.UPDATE_DEALER_ACCOUNT]} requireAll={false}>
          <Accordion
            reduceMotion
            variant="sidebarMenuAccordion"
            allowToggle
            defaultIndex={isActive("/users") || isActive("/company-info") ? [0] : undefined}
          >
            <AccordionItem>
              <AccordionButton>
                <Flex alignItems="center" w="full">
                  <Icon name="admin_panel_settings" mr="2" />
                  <Text>{translate("sidebar.settings")}</Text>
                </Flex>
              </AccordionButton>

              <AccordionPanel>
                <FeatureGate features={[FeatureFlagEnum.USER_MANAGEMENT]}>
                  <Authorize require={[Permissions.READ_EXTERNAL_USER]}>
                    <ChakraLink as={ReactRouterLink} to="/users" variant={getVariant(["/users"], true)}>
                      <Text>{translate("sidebar.users")}</Text>
                    </ChakraLink>
                  </Authorize>
                </FeatureGate>

                <Authorize require={[Permissions.UPDATE_DEALER_ACCOUNT]}>
                  <ChakraLink as={ReactRouterLink} to="/company-info" variant={getVariant(["/company-info"], true)}>
                    <Text>{translate("sidebar.company_details")}</Text>
                  </ChakraLink>
                </Authorize>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Authorize>
        <FeatureGate features={[FeatureFlagEnum.BLANCO_LOANS_ENABLED]}>
          <Authorize require={[Permissions.READ_CONSUMER_CREDIT_APPLICATION]}>
            <ChakraLink as={ReactRouterLink} to="/loans" variant={getVariant("/loans")}>
              <Flex alignItems="center" w="full">
                <Icon name="payments" />
                <Text ml="2">{translate("sidebar.consumer_loans")}</Text>
              </Flex>
            </ChakraLink>
          </Authorize>
        </FeatureGate>

        {kycApplicationId &&
          (kycApplicationStatus === "pending" ||
            kycApplicationStatus === "submitted" ||
            kycApplicationStatus === "rejected") && (
            <Authorize require={[Permissions.UPDATE_KYC_APPLICATION]}>
              <ChakraLink as={ReactRouterLink} to={`/kyc/application/${kycApplicationId}`} variant={getVariant("/kyc")}>
                <Flex alignItems="center" w="full">
                  <Icon name="business_center" mr="2" />
                  <Text>{translate("sidebar.kyc")}</Text>
                </Flex>
              </ChakraLink>
            </Authorize>
          )}
        <Authorize require={[Permissions.CREATE_FILE]}>
          <ChakraLink as={ReactRouterLink} to="/transactions" variant={getVariant("/transactions")}>
            <Flex alignItems="center" w="full">
              <Icon name="receipt_long" mr="2" />
              <Text>{translate("sidebar.transactions")}</Text>
            </Flex>
          </ChakraLink>
        </Authorize>
      </Box>
    </Flex>
  );
};

export default Sidebar;
