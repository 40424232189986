import { Box, Center } from "@chakra-ui/react";
import React from "react";
import { ReactComponent as AccountCircle } from "@material-design-icons/svg/outlined/account_circle.svg";
import { ReactComponent as Add } from "@material-design-icons/svg/outlined/add.svg";
import { ReactComponent as AddCard } from "@material-design-icons/svg/outlined/add_card.svg";
import { ReactComponent as ArrowBack } from "@material-design-icons/svg/outlined/arrow_back.svg";
import { ReactComponent as ArrowDropDown } from "@material-design-icons/svg/outlined/arrow_drop_down.svg";
import { ReactComponent as BusinessCenter } from "@material-design-icons/svg/outlined/business_center.svg";
import { ReactComponent as CalendarMonth } from "@material-design-icons/svg/outlined/calendar_month.svg";
import { ReactComponent as Chat } from "@material-design-icons/svg/outlined/chat.svg";
import { ReactComponent as CheckCircle } from "@material-design-icons/svg/outlined/check_circle.svg";
import { ReactComponent as ChevronLeft } from "@material-design-icons/svg/outlined/chevron_left.svg";
import { ReactComponent as ChevronRight } from "@material-design-icons/svg/outlined/chevron_right.svg";
import { ReactComponent as Close } from "@material-design-icons/svg/outlined/close.svg";
import { ReactComponent as CloudUpload } from "@material-design-icons/svg/outlined/cloud_upload.svg";
import { ReactComponent as ContactSupport } from "@material-design-icons/svg/outlined/contact_support.svg";
import { ReactComponent as Description } from "@material-design-icons/svg/outlined/description.svg";
import { ReactComponent as DirectionsCar } from "@material-design-icons/svg/outlined/directions_car.svg";
import { ReactComponent as PinDrop } from "@material-design-icons/svg/outlined/pin_drop.svg";
import { ReactComponent as Done } from "@material-design-icons/svg/outlined/done.svg";
import { ReactComponent as EmojiTransportation } from "@material-design-icons/svg/outlined/emoji_transportation.svg";
import { ReactComponent as Error } from "@material-design-icons/svg/outlined/error.svg";
import { ReactComponent as ExpandMore } from "@material-design-icons/svg/outlined/expand_more.svg";
import { ReactComponent as ExpandLess } from "@material-design-icons/svg/outlined/expand_less.svg";
import { ReactComponent as Handshake } from "@material-design-icons/svg/outlined/handshake.svg";
import { ReactComponent as Home } from "@material-design-icons/svg/outlined/home.svg";
import { ReactComponent as HourglassEmpty } from "@material-design-icons/svg/outlined/hourglass_empty.svg";
import { ReactComponent as Key } from "@material-design-icons/svg/outlined/key.svg";
import { ReactComponent as Logout } from "@material-design-icons/svg/outlined/logout.svg";
import { ReactComponent as ManageAccounts } from "@material-design-icons/svg/outlined/manage_accounts.svg";
import { ReactComponent as ManageSearch } from "@material-design-icons/svg/outlined/manage_search.svg";
import { ReactComponent as Menu } from "@material-design-icons/svg/outlined/menu.svg";
import { ReactComponent as MoreVert } from "@material-design-icons/svg/outlined/more_vert.svg";
import { ReactComponent as Payments } from "@material-design-icons/svg/outlined/payments.svg";
import { ReactComponent as PriorityHigh } from "@material-design-icons/svg/outlined/priority_high.svg";
import { ReactComponent as ReceiptLong } from "@material-design-icons/svg/outlined/receipt_long.svg";
import { ReactComponent as Remove } from "@material-design-icons/svg/outlined/remove.svg";
import { ReactComponent as Report } from "@material-design-icons/svg/outlined/report.svg";
import { ReactComponent as Schedule } from "@material-design-icons/svg/outlined/schedule.svg";
import { ReactComponent as Search } from "@material-design-icons/svg/outlined/search.svg";
import { ReactComponent as Send } from "@material-design-icons/svg/outlined/send.svg";
import { ReactComponent as SubdirectoryArrowLeft } from "@material-design-icons/svg/outlined/subdirectory_arrow_left.svg";
import { ReactComponent as SwapVert } from "@material-design-icons/svg/outlined/swap_vert.svg";
import { ReactComponent as Visibility } from "@material-design-icons/svg/outlined/visibility.svg";
import { ReactComponent as Warning } from "@material-design-icons/svg/outlined/warning.svg";
import { ReactComponent as AssignmentLate } from "@material-design-icons/svg/outlined/assignment_late.svg";
import { ReactComponent as StickyNote2 } from "@material-design-icons/svg/outlined/sticky_note_2.svg";
import { ReactComponent as Event } from "@material-design-icons/svg/outlined/event.svg";
import { ReactComponent as PriceCheck } from "@material-design-icons/svg/outlined/price_check.svg";
import { ReactComponent as PeopleAlt } from "@material-design-icons/svg/outlined/people_alt.svg";
import { ReactComponent as AdminPanelSettings } from "@material-design-icons/svg/outlined/admin_panel_settings.svg";
import { ReactComponent as Refresh } from "@material-design-icons/svg/outlined/refresh.svg";

type NoIconProps = {
  name: string;
};

const NoIcon = ({ name }: NoIconProps) => {
  console.error(`Icon ${name} not found`);

  return <Box as="span" />;
};

type Props = {
  name: string;
  size?: string;
  weight?: string;
  p?: string;
  px?: string;
  py?: string;
  pl?: string;
  pr?: string;
  pt?: string;
  pb?: string;
  m?: string;
  mx?: string;
  my?: string;
  ml?: string;
  mr?: string;
  mt?: string;
  mb?: string;
  opacity?: string;
  color?: string;
};

interface IconMapping {
  [key: string]: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
}

const Icon = ({ name, size = "24px", p, px, py, pl, pr, pt, pb, m, mx, my, ml, mr, mt, mb, opacity, color }: Props) => {
  const iconMapping: IconMapping = {
    account_circle: AccountCircle,
    add: Add,
    add_card: AddCard,
    arrow_back: ArrowBack,
    arrow_drop_down: ArrowDropDown,
    business_center: BusinessCenter,
    calendar_month: CalendarMonth,
    chat: Chat,
    check_circle: CheckCircle,
    chevron_left: ChevronLeft,
    chevron_right: ChevronRight,
    close: Close,
    cloud_upload: CloudUpload,
    contact_support: ContactSupport,
    description: Description,
    directions_car: DirectionsCar,
    pin_drop: PinDrop,
    done: Done,
    emoji_transportation: EmojiTransportation,
    error: Error,
    expand_more: ExpandMore,
    expand_less: ExpandLess,
    handshake: Handshake,
    home: Home,
    hourglass_empty: HourglassEmpty,
    key: Key,
    logout: Logout,
    manage_accounts: ManageAccounts,
    manage_search: ManageSearch,
    menu: Menu,
    more_vert: MoreVert,
    payments: Payments,
    priority_high: PriorityHigh,
    receipt_long: ReceiptLong,
    remove: Remove,
    report: Report,
    schedule: Schedule,
    search: Search,
    send: Send,
    subdirectory_arrow_left: SubdirectoryArrowLeft,
    swap_vert: SwapVert,
    visibility: Visibility,
    warning: Warning,
    assignment_late: AssignmentLate,
    sticky_note_2: StickyNote2,
    event: Event,
    price_check: PriceCheck,
    people_alt: PeopleAlt,
    admin_panel_settings: AdminPanelSettings,
    refresh: Refresh,
  };

  const IconComponent = iconMapping[name] || NoIcon;

  return (
    <Center
      p={p}
      px={px}
      py={py}
      pl={pl}
      pr={pr}
      pt={pt}
      pb={pb}
      m={m}
      mx={mx}
      my={my}
      ml={ml}
      mr={mr}
      mt={mt}
      mb={mb}
      opacity={opacity}
      fill={color ? color : "currentColor"}
      w={size}
      h={size}>
      <IconComponent width={size} height={size} name={name} />
    </Center>
  );
};

export default Icon;
