const AUTH0_DOMAIN = process.env.REACT_APP_AUTH0_DOMAIN ? process.env.REACT_APP_AUTH0_DOMAIN : "";
const AUTH0_CLIENT_ID = process.env.REACT_APP_AUTH0_CLIENT_ID ? process.env.REACT_APP_AUTH0_CLIENT_ID : "";
const AUTH0_AUDIENCE = process.env.REACT_APP_AUTH0_AUDIENCE ? process.env.REACT_APP_AUTH0_AUDIENCE : "";

const BASE_URL = process.env.REACT_APP_BASE_URL ? process.env.REACT_APP_BASE_URL : "";
let API_BASE_URL = process.env.REACT_APP_API_BASE_URL ? process.env.REACT_APP_API_BASE_URL : "";
const IT_SUPPORT_EMAIL = process.env.REACT_APP_IT_SUPPORT_EMAIL ? process.env.REACT_APP_IT_SUPPORT_EMAIL : "";
const DEFAULT_LOCALE = process.env.REACT_APP_DEFAULT_LOCALE ? process.env.REACT_APP_DEFAULT_LOCALE : "sv";

const MARKER_IO_PROJECT = process.env.REACT_APP_MARKER_IO_PROJECT ? process.env.REACT_APP_MARKER_IO_PROJECT : "";

const CHAT_CHIME_APP_INSTANCE_ARN: string = process.env.REACT_APP_CHAT_CHIME_APP_INSTANCE_ARN
    ? process.env.REACT_APP_CHAT_CHIME_APP_INSTANCE_ARN
    : "";


const SENTRY_DNS = process.env.REACT_APP_SENTRY_DNS ? process.env.REACT_APP_SENTRY_DNS : "";
const TABLE_LIMIT = 15;
const NOTES_LIMIT = 10;
const DOMAIN_EVENTS_LIMIT = 10;



const RUN_LOCALLY = process.env.REACT_APP_RUN_LOCALLY ? process.env.REACT_APP_RUN_LOCALLY==="true" : false;
const LOCAL_AUTH_URL = process.env.REACT_APP_LOCAL_AUTH_URL ? process.env.REACT_APP_LOCAL_AUTH_URL : "";
const LOCAL_API_BASE_URL = process.env.REACT_APP_LOCAL_API_BASE_URL ? process.env.REACT_APP_LOCAL_API_BASE_URL : "";
const LOCAL_AUTH_API_BASE_URL = process.env.REACT_APP_LOCAL_AUTH_API_BASE_URL ? process.env.REACT_APP_LOCAL_AUTH_API_BASE_URL : "";
if (RUN_LOCALLY){
  API_BASE_URL = LOCAL_API_BASE_URL;
}

export {
  AUTH0_DOMAIN,
  AUTH0_CLIENT_ID,
  AUTH0_AUDIENCE,
  BASE_URL,
  API_BASE_URL,
  DEFAULT_LOCALE,
  IT_SUPPORT_EMAIL,
  MARKER_IO_PROJECT,
  TABLE_LIMIT,
  NOTES_LIMIT,
  DOMAIN_EVENTS_LIMIT,
  SENTRY_DNS,
  CHAT_CHIME_APP_INSTANCE_ARN,
  LOCAL_AUTH_URL,
  RUN_LOCALLY,
  LOCAL_AUTH_API_BASE_URL
};
